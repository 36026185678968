// WordTranscription.jsx

/**
 * WordTranscription.jsx
 *
 * This module generates a Word document for a single call's transcription.
 * It uses the 'docx' library to create the document structure.
 */

import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  Footer,
  AlignmentType,
  ExternalHyperlink,
  PageNumber,
} from "docx";
import { secsToMinsHours } from "../utils/time";

/**
 * Generates a Word document for a given call.
 * @param {Object} currentCall - The call data.
 * @param {Boolean} useRedacted - Whether to use redacted text.
 * @param {Boolean} returnBlob - If true, returns the blob instead of initiating download.
 * @returns {Promise<Blob|void>} - Returns a Blob if returnBlob is true.
 */
const WordTranscription = async (
  currentCall,
  useRedacted,
  returnBlob = false
) => {
  const lines = [];
  const transcription = currentCall.transcription;

  // Map each line of the transcription to Word document elements
  transcription.forEach((line) => {
    const metaData = new Paragraph({
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.LEFT,
      spacing: {
        after: 100,
      },
      children: [
        new TextRun({
          text: `${line.speaker}:`,
          size: 24,
          underline: true,
          bold: true,
          color: "000000",
        }),
        new TextRun({
          text: `   ${secsToMinsHours(line.start_time)}`,
          size: 22,
          color: "8294a5",
        }),
      ],
    });
    const text = new Paragraph({
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.LEFT,
      spacing: {
        after: 300,
      },
      children: [
        new TextRun({
          text:
            useRedacted && line.redactedText ? line.redactedText : line.text,
          size: 24,
          color: "000000",
        }),
      ],
    });
    lines.push(metaData);
    lines.push(text);
  });

  // Create the Word document
  const doc = new Document({
    sections: [
      {
        properties: {},
        footers: {
          default: new Footer({
            children: [
              // Footer content
              new Paragraph({
                heading: HeadingLevel.HEADING_3,
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "~ Created With ",
                    size: 24,
                    color: "808080",
                  }),
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        text: "WireTap",
                        style: "Hyperlink",
                      }),
                    ],
                    link: "https://wiretaptech.com",
                  }),
                  new TextRun({ text: " ~", size: 24, color: "808080" }),
                ],
              }),
              // Page numbers in footer
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: [
                      "Page ",
                      PageNumber.CURRENT,
                      " of ",
                      PageNumber.TOTAL_PAGES,
                    ],
                    size: 12,
                    color: "808080",
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            // Title
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            spacing: {
              after: 100,
            },
            children: [
              new TextRun({
                text: currentCall.audioFileName,
                size: 40,
                color: "000000",
              }),
            ],
          }),
          new Paragraph({
            // Case Name
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            spacing: {
              after: 100,
            },
            children: [
              new TextRun({
                text: currentCall.project.title,
                italics: true,
                size: 24,
                color: "808080",
              }),
            ],
          }),
          new Paragraph({
            // Date and Time
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            spacing: {
              after: 300,
            },
            children: [
              new TextRun({
                text: `${currentCall.date} - ${currentCall.time}`,
                italics: true,
                size: 24,
                color: "808080",
              }),
            ],
            border: {
              bottom: {
                color: "000000",
                space: 10,
                style: "single",
                size: 6,
              },
            },
          }),
          ...lines,
        ],
      },
    ],
  });

  // Generate the document as a Blob
  const blob = await Packer.toBlob(doc);

  if (returnBlob) {
    // Return the blob to be used elsewhere (e.g., added to a zip)
    return blob;
  } else {
    // Initiate download
    const href = URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const link = document.createElement("a");
    link.href = href;
    link.download = `${currentCall.audioFileName}.docx`;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
};

export default WordTranscription;
