// components/Transcript.jsx

/**
 * Transcript.jsx
 *
 * This component displays the transcript of the call.
 * It includes the ability to toggle between redacted and unredacted text.
 * It also displays a summary if available.
 */

import React, { useCallback } from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// Custom Components
import CustomTooltip from "./CustomTooltip.js";
import ClassifierSummary from "./ClassifierSummary.jsx";

// Utils
import { secsToMinsHours } from "../utils/time.js";
import { stringToColor } from "../utils/helper functions/call_helpers.js";
import { DISPLAY_CLASSIFIER_IN_CALL_PAGE } from "../utils/constants.js";

// Styled Components
const LeftSideWrapper = styled.div`
  flex-grow: 1;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TranscriptHeading = styled.div`
  font-weight: 650;
  font-size: 1.3rem;
  color: ${(props) => props.theme.heading};
  text-decoration: underline;
  margin-bottom: 20px;
`;

const TranscriptBox = styled.div`
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TranscriptInfo = styled.div`
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
`;

const TranscriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

const SpeakerName = styled.span`
  font-weight: bold;
  color: black;
  margin-right: 15px;
`;

const SpeakerTime = styled.span`
  color: #8294a5;
  font-weight: 400;
`;

const SpeakerText = styled.div``;

const Transcript = ({ currentCall, showRedacted, setShowRedacted }) => {
  /**
   * Generates an avatar based on the speaker's name.
   * @param {string} name - The speaker's name.
   * @param {string} color - The avatar color.
   * @param {boolean} small - Whether to use a small avatar.
   * @returns {Object} Avatar properties.
   */
  const stringAvatar = useCallback((name, color, small = false) => {
    let initials = name.split(" ")[0][0];
    if (name.split(" ").length > 1) {
      initials += name.split(" ")[1][0];
    }
    if (!color) color = stringToColor(name);

    if (small) {
      return {
        sx: {
          bgcolor: color,
          width: "30px",
          height: "30px",
          fontSize: "12px",
          fontWeight: "400",
          border: "2px solid lightgrey",
          boxSizing: "border-box",
        },
        children: initials,
      };
    }

    return {
      sx: {
        bgcolor: color,
        width: "40px",
        height: "40px",
        fontSize: "16px",
        fontWeight: "600",
        border: "2px solid lightgrey",
        boxSizing: "border-box",
      },
      children: initials,
    };
  }, []);

  /**
   * Generates the transcript JSX.
   * @returns {JSX.Element[]} Array of transcript lines.
   */
  const getTranscript = useCallback(() => {
    let speakerColorMap = {};

    let callTranscript = currentCall.transcription;

    callTranscript.forEach((line) => {
      let speaker = line.speaker;
      if (!speakerColorMap[speaker]) {
        speakerColorMap[speaker] = stringToColor(speaker);
      }
    });

    return callTranscript.map((line, index) => {
      let speaker = line.speaker;
      return (
        <TranscriptBox key={index}>
          <Avatar {...stringAvatar(speaker, speakerColorMap[speaker])} />
          <TranscriptInfo>
            <TranscriptionHeader>
              <SpeakerName>{speaker}:</SpeakerName>
              <SpeakerTime>{secsToMinsHours(line.start_time)}</SpeakerTime>
            </TranscriptionHeader>
            <SpeakerText>
              {showRedacted && line.redactedText
                ? line.redactedText
                : line.text}
            </SpeakerText>
          </TranscriptInfo>
        </TranscriptBox>
      );
    });
  }, [currentCall, stringAvatar, showRedacted]);

  /**
   * Renders the summary component if available.
   * @returns {JSX.Element} The summary component.
   */
  const getSummary = useCallback(() => {
    if (currentCall && currentCall.classifierSummary) {
      return <ClassifierSummary currentCall={currentCall} />;
    } else {
      return <React.Fragment />;
    }
  }, [currentCall]);

  return (
    <LeftSideWrapper>
      {DISPLAY_CLASSIFIER_IN_CALL_PAGE && getSummary()}
      <TranscriptHeading>Transcript</TranscriptHeading>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {
          // Only show switch if PII redaction exists
          currentCall.transcription?.some((item) => item.redactedText) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Switch
                checked={showRedacted}
                onChange={() => setShowRedacted(!showRedacted)}
                color="primary"
                title="Toggle to show PII Redacted Transcript"
              />
              <span>Show PII Redacted Transcript</span>
              <CustomTooltip
                title="PII (Personally Identifiable Information) redaction removes sensitive information to protect individual privacy. Toggle the switch to view or download the redacted version."
                placement="right"
              >
                <IconButton size="small">
                  <HelpOutlineIcon fontSize="inherit" />
                </IconButton>
              </CustomTooltip>
            </div>
          )
        }
      </div>
      {getTranscript()}
    </LeftSideWrapper>
  );
};

export default Transcript;
