// components/LoadingScreen.jsx

/**
 * LoadingScreen.jsx
 *
 * This component displays a loading indicator when data is being fetched or processed.
 * It shows a backdrop with a loader and an optional message.
 */

import React from "react";
import styled from "styled-components";
import Backdrop from "@mui/material/Backdrop";

// Custom Components
import Loader from "./Loader.jsx";

// Styled Components
const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TranscribeAudio = styled.div`
  color: ${(props) => props.theme.primaryWhite};
  margin-top: 10px;
`;

const LoadingScreen = ({ loading, stillTranscribing }) => {
  return (
    <Backdrop open={loading} style={{ zIndex: 1400 }}>
      <LoadingWrapper>
        <Loader width={40} height={40} fill="white" />
        {stillTranscribing && (
          <TranscribeAudio>
            Please wait while we transcribe audio...
          </TranscribeAudio>
        )}
      </LoadingWrapper>
    </Backdrop>
  );
};

export default LoadingScreen;
