// pages/CallPage.jsx

/**
 * CallPage.jsx
 *
 * This file represents the main page for displaying a call's transcript.
 * It fetches the call data, manages state, and orchestrates the rendering of child components.
 */

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

// Custom Components
import MainJumbotron from "../components/MainJumbotron.jsx";
import Transcript from "../components/Transcript.jsx";
import SpeakerList from "../components/SpeakerList.jsx";
import PDFDownloadButton from "../components/PDFDownloadButton.jsx";
import WordTranscription from "../components/WordTranscription.jsx";
import LoadingScreen from "../components/LoadingScreen.jsx";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import StyledButton from "../components/StyledButton.jsx";

// Utils and Context
import { getCall } from "../utils/queries.js";
import { updateTranscription } from "../utils/mutations.js";
import { subscribeUpdateCall } from "../utils/subscriptions.js";
import { UserContext } from "../utils/context.js";
import { IN_PROGRESS, COMPLETE, TIMEOUT } from "../utils/constants.js";
import { getKeyTermsOnlyJSX } from "../utils/helper functions/keyterms_contains_helper.js";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";

// SEO
import SEO from "../components/SEO.jsx";

// Styled Components
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;

  .MuiBackdrop-root {
    z-index: 3;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

// Right Side Styles
const RightSideWrapper = styled.div`
  width: 25%;
  border-left: 2px solid grey;
  min-width: 290px;
`;

const ContentWrapper = styled.div`
  position: sticky;
  top: 20px;
`;

const BorderedBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid lightgrey;
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
`;

const ButtonsWrapper = styled(BorderedBox)`
  border: none;
`;

const BoxHeading = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.heading};
  margin-bottom: 10px;
`;

const EvidenceLine = styled.div`
  font-size: 1rem;
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
`;

const StyledLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
  font-size: 1.4rem;
`;

const FailedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.textGrey};
  font-size: 1.9rem;
`;

const CallPage = ({ match }) => {
  // State variables
  const [currentCall, setCurrentCall] = useState(undefined);
  const [speakers, setSpeakers] = useState(undefined);
  const [newSpeakerNames, setNewSpeakerNames] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [stillTranscribing, setStillTranscribing] = useState(false);
  const [callFailed, setCallFailed] = useState(false);
  const [failureReason, setFailureReason] = useState(undefined);
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [showRedacted, setShowRedacted] = useState(false);

  const history = useHistory();
  const user = useContext(UserContext);

  /**
   * Sets the current call and updates the loading states.
   * Ensures that loading is only set to false after speakers are initialized.
   * @param {Object} call - The call object retrieved from AWS.
   */
  const setCall = async (call) => {
    setCurrentCall(call);
    // Speakers will be set in the next useEffect
  };

  /**
   * Fetches the call from AWS and handles different statuses.
   */
  useEffect(() => {
    let callSubscription;

    const getCallFromAWS = async () => {
      try {
        const callID = match.params.id;
        const call = await getCall(callID);

        if (typeof call === "string") {
          console.error(`Failed to receive call due to error: ${call}`);
          history.push("/unauthorized");
          return;
        }

        switch (call.status) {
          case COMPLETE:
            setCall(call);
            break;
          case IN_PROGRESS:
            setStillTranscribing(true);
            callSubscription = subscribeUpdateCall(
              call.project.id,
              callID,
              setCall
            );
            break;
          default:
            setCallFailed(true);
            setFailureReason(call.errorMessage || TIMEOUT);
            setLoading(false);
            setStillTranscribing(false);
            setCurrentCall(call);
        }
      } catch (error) {
        console.error("Error fetching call:", error);
      }
    };

    if (user) {
      getCallFromAWS();
    }

    return () => {
      if (callSubscription) {
        callSubscription.unsubscribe();
      }
    };
  }, [history, match.params.id, user]);

  /**
   * Extracts unique speakers from the call transcription.
   * Sets the speakers and newSpeakerNames state variables.
   * Sets loading to false once speakers are initialized.
   */
  useEffect(() => {
    if (currentCall && currentCall.transcription && !callFailed) {
      const uniqueSpeakers = [
        ...new Set(currentCall.transcription.map(({ speaker }) => speaker)),
      ];

      setSpeakers(uniqueSpeakers);
      setNewSpeakerNames(new Array(uniqueSpeakers.length).fill(""));

      // Now that speakers are set, we can set loading to false
      setLoading(false);
      setStillTranscribing(false);
    }
  }, [currentCall, callFailed]);

  /**
   * Updates the speakers' names in the transcription and sends the update to AWS.
   */
  const updateSpeakers = () => {
    const speakerMap = createSpeakerMap();
    let updatedTranscript = currentCall.transcription.map((line) => ({
      ...line,
      speaker: speakerMap[line.speaker] || line.speaker,
    }));

    setCurrentCall({
      ...currentCall,
      transcription: updatedTranscript,
    });
    updateTranscription(match.params.id, JSON.stringify(updatedTranscript));
  };

  /**
   * Creates a mapping from old speaker names to new speaker names.
   * Ensures no duplicate names are used.
   * @returns {Object} speakerMap - The mapping of old to new speaker names.
   */
  const createSpeakerMap = () => {
    let speakerMap = {};
    let usedNames = new Set(speakers);

    for (const index in speakers) {
      const trimmedNewSpeakerName = newSpeakerNames[index].trim();

      if (trimmedNewSpeakerName.length > 0) {
        if (!usedNames.has(trimmedNewSpeakerName)) {
          speakerMap[speakers[index]] = trimmedNewSpeakerName;
          usedNames.add(trimmedNewSpeakerName);
        } else {
          setWarnSnackbar(
            `Please do not use a name already in use: ${trimmedNewSpeakerName}`
          );
          speakerMap[speakers[index]] = speakers[index];
        }
      } else {
        speakerMap[speakers[index]] = speakers[index];
      }
    }
    return speakerMap;
  };

  /**
   * Retrieves the evidence/key terms found in the call.
   * @returns {JSX.Element} The key terms JSX or a message if none found.
   */
  const getEvidence = () => {
    if (
      currentCall &&
      currentCall.contains &&
      currentCall.contains.length > 0
    ) {
      return (
        <b>
          {getKeyTermsOnlyJSX(currentCall.contains, KEY_TERMS_BOLD_DELIMINATOR)}
        </b>
      );
    }
    return "No key terms searched/found on audio yet.";
  };

  return (
    <Wrapper>
      <SEO
        title="Transcript | WireTap"
        description="Look at a transcript for your call, body cam, or audio file. Adjust the speakers' names on the file. Download the transcription of the file for future use."
      />
      <MainJumbotron
        title={currentCall ? currentCall.audioFileName : ""}
        metrics={[
          { header: "Date", body: currentCall ? currentCall.date : "" },
          { header: "Time", body: currentCall ? currentCall.time : "" },
          {
            header: "Duration",
            body: currentCall ? currentCall.durationString : "",
          },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          {
            name: currentCall ? currentCall.project.title : "",
            link: currentCall ? `/case/${currentCall.project.id}` : "",
          },
          { name: currentCall ? currentCall.audioFileName : "" },
        ]}
      />
      {!loading && currentCall && speakers && !callFailed && (
        <BodyWrapper>
          {/* Left Side - Transcript */}
          <Transcript
            currentCall={currentCall}
            showRedacted={showRedacted}
            setShowRedacted={setShowRedacted}
          />

          {/* Right Side - Speaker List and Downloads */}
          <RightSideWrapper>
            <ContentWrapper>
              <BorderedBox>
                <BoxHeading>Key Terms</BoxHeading>
                <EvidenceLine>{getEvidence()}</EvidenceLine>
              </BorderedBox>
              <BorderedBox>
                <BoxHeading>Speakers</BoxHeading>
                <SpeakerList
                  speakers={speakers}
                  newSpeakerNames={newSpeakerNames}
                  setNewSpeakerNames={setNewSpeakerNames}
                />
                <StyledButton
                  color={"#209BCF"}
                  style={{
                    background: "linear-gradient(#FFFFFF, #E9E9E9)",
                    alignSelf: "flex-end",
                    marginTop: "10px",
                  }}
                  size="small"
                  onClick={updateSpeakers}
                >
                  Update Speakers
                </StyledButton>
              </BorderedBox>
              <ButtonsWrapper>
                <StyledButton
                  color={"white"}
                  style={{
                    background: "linear-gradient(#209BCF, #26B7F5)",
                    marginBottom: "15px",
                  }}
                  onClick={() => WordTranscription(currentCall, showRedacted)}
                >
                  {showRedacted ? "Download Redacted Word" : "Download Word"}
                </StyledButton>

                {currentCall.audioFileName && (
                  <PDFDownloadButton
                    currentCall={currentCall}
                    showRedacted={showRedacted}
                  />
                )}
                {!currentCall.audioFileName && (
                  <StyledButton
                    color={"#209BCF"}
                    style={{
                      background: "linear-gradient(#FFFFFF, #E9E9E9)",
                    }}
                    disabled={true}
                  >
                    Loading Doc
                  </StyledButton>
                )}
              </ButtonsWrapper>
            </ContentWrapper>
          </RightSideWrapper>
        </BodyWrapper>
      )}
      {!loading && callFailed && failureReason && (
        <FailedWrapper>
          <h1>Call Failed</h1>
          <InfoText>
            <div>{failureReason}</div>
            <div>
              To return home, click <StyledLink to="/home">here</StyledLink>
            </div>
          </InfoText>
        </FailedWrapper>
      )}
      {loading && (
        <LoadingScreen
          loading={loading}
          stillTranscribing={stillTranscribing}
        />
      )}
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
      />
    </Wrapper>
  );
};

export default CallPage;
