import React from "react";
import styled from "styled-components";
import { VERSION } from "../utils/constants.js";

const Wrapper = styled.footer`
  background-color: ${(props) => props.theme.footerBackground};
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width 100%;
  height: 2.5rem;
  z-index: 100;

`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CopyrightText = styled.div`
  color: rgb(164, 170, 173);
  font-size: 0.75rem;
`;

const Version = styled(CopyrightText)`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const Footer = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <CopyrightText>
          Copyright © {new Date().getFullYear()} WireTap. All rights reserved.
        </CopyrightText>
      </ContentWrapper>
      <Version>v {VERSION}</Version>
    </Wrapper>
  );
};

export default Footer;
