// components/PDFDownloadButton.jsx

/**
 * PDFDownloadButton.jsx
 *
 * This component provides a button to download the transcript as a PDF.
 * It uses useMemo to prevent unnecessary re-renders and improve performance.
 */

import React, { useMemo } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Custom Components
import PDFTranscription from "./PDFTranscription.jsx";
import StyledButton from "./StyledButton.jsx";

const PDFDownloadButton = ({ currentCall, showRedacted }) => {
  /**
   * Memoized PDF document to prevent unnecessary re-renders.
   */
  const pdfDocument = useMemo(
    () => (
      <PDFTranscription currentCall={currentCall} redacted={showRedacted} />
    ),
    [currentCall, showRedacted]
  );

  /**
   * Generates the PDF file name based on the audio file name and redaction status.
   */
  const pdfFileName = useMemo(() => {
    const baseName = currentCall.audioFileName.substring(
      0,
      currentCall.audioFileName.lastIndexOf(".")
    );
    return baseName + (showRedacted ? "_PII_redacted" : "") + ".pdf";
  }, [currentCall.audioFileName, showRedacted]);

  return (
    <PDFDownloadLink document={pdfDocument} fileName={pdfFileName}>
      {({ blob, url, loading, error }) => (
        <StyledButton
          color={"#209BCF"}
          style={{
            background: "linear-gradient(#FFFFFF, #E9E9E9)",
          }}
          disabled={loading || error}
        >
          {error
            ? "Error creating PDF"
            : loading
            ? "Loading Doc"
            : showRedacted
            ? "Download Redacted PDF"
            : "Download PDF"}
        </StyledButton>
      )}
    </PDFDownloadLink>
  );
};

export default React.memo(PDFDownloadButton);
