// components/SpeakerList.jsx

/**
 * SpeakerList.jsx
 *
 * This component displays the list of speakers and allows the user to update their names.
 * It manages the input fields for each speaker and handles changes.
 */

import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";

// Utils
import { stringToColor } from "../utils/helper functions/call_helpers.js";

// Styled Components
const SpeakersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const SingleSpeakerWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TextFieldWrapper = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-fullWidth {
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const SpeakerList = ({ speakers, newSpeakerNames, setNewSpeakerNames }) => {
  /**
   * Generates an avatar based on the speaker's name.
   * @param {string} name - The speaker's name.
   * @returns {Object} Avatar properties.
   */
  const stringAvatar = (name) => {
    let initials = name.split(" ")[0][0];
    if (name.split(" ").length > 1) {
      initials += name.split(" ")[1][0];
    }
    const color = stringToColor(name);

    return {
      sx: {
        bgcolor: color,
        width: "30px",
        height: "30px",
        fontSize: "12px",
        fontWeight: "400",
        border: "2px solid lightgrey",
        boxSizing: "border-box",
      },
      children: initials,
    };
  };

  /**
   * Handles the change event for speaker name inputs.
   * @param {Event} e - The input change event.
   * @param {number} index - The index of the speaker.
   */
  const handleNameChange = (e, index) => {
    const newVal = e.target.value;
    let newSpeakers = [...newSpeakerNames];
    newSpeakers[index] = newVal;
    setNewSpeakerNames(newSpeakers);
  };

  return (
    <SpeakersWrapper>
      {speakers.map((speaker, index) => (
        <SingleSpeakerWrapper key={index}>
          <Avatar {...stringAvatar(speaker)} />
          <TextFieldWrapper>
            <StyledTextField
              fullWidth
              label={speaker}
              value={newSpeakerNames[index]}
              variant="standard"
              onChange={(e) => handleNameChange(e, index)}
            />
          </TextFieldWrapper>
        </SingleSpeakerWrapper>
      ))}
    </SpeakersWrapper>
  );
};

export default SpeakerList;
